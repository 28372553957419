select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

@import '../../node_modules/bootstrap/scss/forms/form-select';

select > option {
  font-weight: 100;
}
