.btn {
  --bs-btn-border-radius: 30px;
  --bs-btn-padding-x: 22px;
  --bs-btn-padding-y: 7px;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 20px;
  --bs-btn-border-width: 2px;
  --bs-btn-font-size: 0.875rem;
}

.btn-lg {
  --bs-btn-font-size: 1rem;
  --bs-btn-padding-x: 32px;
  --bs-btn-padding-y: 9px;
  --bs-btn-line-height: 26px;
}

.btn-primary {
  --bs-btn-color: #007daa;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #007daa;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #007daa;
  --bs-btn-active-bg: #007daa;
  --bs-btn-hover-border-color: #007daa;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e7000f;
  --bs-btn-border-color: #e7000f;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e7000f;
  --bs-btn-active-bg: #e7000f;
  --bs-btn-hover-border-color: #e7000f;
}

.btn-tertiary {
  --bs-btn-color: #717171;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #717171;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #717171;
  --bs-btn-active-bg: #717171;
  --bs-btn-hover-border-color: #717171;
}
