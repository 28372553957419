$form-check-input-width: 18px;
$line-height-base: 22px;
$form-switch-width: 3rem;

@import '../../node_modules/bootstrap/scss/forms/form-check';

.form-switch .form-check-input {
  background-color: #adacac;
  border-color: #adacac;
  height: 24px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='rgb%28255, 255, 255%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-color: #007daa;
}
