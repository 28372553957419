.arrow-btn.btn {
  background: color.$light-gray3;
  min-width: 26px;
  height: 26px;
  border-radius: 4px;
  border-color: color.$light-gray3;
  padding: 0;
  transition: transform 0.4s ease-in-out;
  &:hover,
  &:active {
    background-color: color.$light-gray3;
    border-color: color.$light-gray3;
  }
  &.down {
    transform: rotate(0deg);
  }
  &.up {
    transform: rotate(180deg);
  }
}
