
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
@import './bootstrap_includes';

@font-face {
  font-family: 'ls-icon';
  src: url('../fonts/ls-icon-v12.eot');
  src:
    url('../fonts/ls-icon-v12.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ls-icon-v12.woff') format('woff'),
    url('../fonts/ls-icon-v12.woff2') format('woff2'),
    url('../fonts/ls-icon-v12.ttf') format('truetype'),
    url('../fonts/ls-icon-v12.svg#ls-icon') format('svg');
  font-display: swap;
}

a {
  text-decoration: none;
}
body {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.015em;
  background-color: #fff;
  color: #020202;
  font-weight: 100;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
@media screen and (min-width: 768px) {
  body {
    letter-spacing: -0.015em;
  }
}
@media (prefers-color-scheme: dark) {
  body {
    color: #020202;
    background: #fff;
  }
}
p,
ul {
  margin: 0;
  padding: 0;
}
p li,
ul li {
  list-style: none;
}
.main-page:focus-visible {
  outline: none;
}
.mp-pusher {
  transition: all 0.5s;
}
@media screen and (min-width: 991px) {
  .mobileNavTop {
    display: none;
  }
}
.mobileNavTop {
  overflow: auto;
  height: 100%;
  max-height: 100vh;
  padding-bottom: 115px;
}
@media (max-width: 991px) {
  .smartbanner + #wrapper .mp-pushed #mp-menu .mobileNavTop {
    padding-bottom: 250px;
  }
  .mp-pushed {
    transform: translate3d(100%, 0, 0);
    position: fixed;
    width: 100%;
  }
  .mp-pushed #mp-menu .mp-level {
    position: relative;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .mp-pushed {
    transform: translate3d(375px, 0, 0);
  }
}
@media (max-width: 991px) {
  .mp-pushed:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9999;
    transition: opacity 0.3s;
  }
}
.hidden {
  display: none;
}
.account-icons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  padding: 0;
  line-height: 13px;
  margin-left: 0;
}
@media screen and (min-width: 376px) {
  .account-icons {
    margin-left: 3px;
  }
}
@media screen and (min-width: 992px) {
  .account-icons {
    margin-left: auto;
    line-height: 13px;
  }
}
.account-icons > li {
  margin-right: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 300;
  height: 56px;
}
@media screen and (min-width: 768px) {
  .account-icons > li {
    height: auto;
  }
}
.account-icons > li:last-child {
  margin-right: 0;
  margin-left: 6px;
}
@media screen and (min-width: 992px) {
  .account-icons > li:last-child {
    margin-left: 20px;
  }
}
.account-icons > li > a {
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  display: block;
}
.account-icons > li > a .link-wrapper {
  margin-top: 6px;
  line-height: 13px;
}
@media (min-width: 992px) {
  .nav-tools {
    display: flex;
    font-size: 12px;
    color: #fff;
    line-height: 1;
    margin: 0;
    padding: 12px 0;
    justify-content: flex-end;
    position: relative;
    z-index: 9;
  }
  .nav-tools > li {
    border-right: 1px solid #fff;
    height: 12px;
    list-style: none;
    padding: 0 8px;
    white-space: nowrap;
  }
  .nav-tools > li .store-icon {
    width: 17px;
    height: 17px;
    background: url(/assets/images/LakeshoreSprites_v16.svg) -68px 0/170px 170px;
    display: inline-block;
    margin: -2px 0 0;
  }
  .nav-tools > li > a {
    color: #fff;
    font-weight: 100;
    letter-spacing: -0.015em;
  }
  .nav-tools > li > a:hover {
    text-decoration: underline;
  }
  .nav-tools > li:last-child {
    border-right: 0;
    padding-right: 0;
    padding-left: 8px;
  }
  .nav-tools > li > .link-wrapper {
    display: inline-flex;
    padding-left: 5px;
    position: relative;
  }
  .nav-tools > li > .link-wrapper > a {
    color: #fff;
  }
  .nav-tools > li > .link-wrapper > a:hover {
    text-decoration: underline;
  }
  .nav-tools > li > .link-wrapper .storePopoverWrapper {
    display: none;
    position: absolute;
    min-width: 237px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    padding-top: 10px;
    top: 100%;
  }
  .nav-tools > li > .link-wrapper:hover .storePopoverWrapper {
    display: block;
  }
  .nav-tools > li > .link-wrapper .dropdown-header {
    font-weight: 700;
    line-height: 22px;
    color: #020202;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .nav-tools > li > .link-wrapper .storePopoverWrapper ul {
    padding-left: 43px;
  }
  .nav-tools > li > .link-wrapper .dropdown-list-items {
    list-style: circle;
    line-height: 22px;
    margin: 0 0 2px;
  }
  .nav-tools > li > .link-wrapper .dropdown-list-items a {
    color: #020202;
  }
  .nav-tools > li > .link-wrapper .dropdown-list-items a:hover {
    text-decoration: underline;
  }
  .nav-tools > li > .link-wrapper a.dropdown-img {
    display: block;
    margin: 20px 0 5px;
  }
  .nav-tools .store-li {
    display: flex;
    position: relative;
  }
}
.container {
  max-width: 100%;
  padding: 0 12px;
}
.pdp-main-container,
.plp-main-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto 20px;
  padding: 0;
  overflow-x: hidden;
}
@media screen and (min-width: 1500px) {
  .pdp-main-container,
  .plp-main-container {
    max-width: 1500px;
    padding: 0;
  }
}
.pdp-main-container.pdp,
.plp-main-container.pdp {
  max-width: 1170px;
  padding: 0 15px;
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
  .pdp-main-container.pdp,
  .plp-main-container.pdp {
    max-width: 970px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .pdp-main-container.pdp,
  .plp-main-container.pdp {
    max-width: 750px;
  }
}
.home-main-container {
  margin: 0 auto 20px;
  overflow-x: hidden;
}
.page-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1500px) {
  .page-container {
    max-width: 1500px;
  }
}
.full-width-container {
  width: 100%;
  max-width: 100%;
}
.global-footer .footer-accordion-header span.collapsed:after {
  transform: rotate(90deg);
}
#footer .text-terms p.footer-mobile {
  margin-bottom: 15px;
  padding: 0 3px;
  color: #020202;
}
#footer .text-terms p.footer-mobile a {
  color: #020202;
  text-decoration: underline;
}
#footer .text-terms .blueLink {
  color: #007daa;
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  #footer .text-terms .blueLink {
    font-size: 12px;
  }
}
#footer .footer-social {
  margin: 10px 0 15px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  #footer .footer-social {
    text-align: left;
    margin: 18px 0 15px;
  }
}
#footer .footer-social a {
  color: #f8f8f8;
  font-size: 33px;
  margin-right: 8px;
  padding: 2px;
  width: 29px;
  height: 29px;
  display: inline-block;
}
#footer .footer-social .instagram {
  background: url(/assets/images/LakeshoreSprites_v16.svg) 87px -116px/290px
    290px;
}
#footer .footer-social .facebook {
  background: url(/assets/images/LakeshoreSprites_v16.svg) 58px -116px/290px
    290px;
}
#footer .footer-social .pinterest {
  background: url(/assets/images/LakeshoreSprites_v16.svg) 29px -116px/290px
    290px;
}
#footer .footer-social .youtube {
  background: url(/assets/images/LakeshoreSprites_v16.svg) 0 -145px/290px 290px;
}
#footer .footer-social .twitter {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -29px -145px/290px
    290px;
}
#footer .footer-social .linkedin {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -58px -145px/290px
    290px;
}
#global-footer {
  font-weight: 100;
}
#global-footer .footer-get-app {
  margin-bottom: 10px;
  color: #020202;
}
#global-footer .footer-get-app img.autofill {
  max-width: 100%;
}
@media screen and (min-width: 768px) {
  #global-footer .footer-get-app {
    display: flex;
    margin-left: auto;
    float: none;
  }
}
#global-footer .footer-get-app .footer-header {
  font-weight: 700;
  letter-spacing: -0.015em;
  font-size: 22px;
  color: #020202;
  line-height: 22px;
  border-top: 1px solid #ccc;
}
@media screen and (min-width: 768px) {
  #global-footer .footer-get-app .footer-header {
    border: 0;
  }
}
#global-footer .footer-get-app .qr-code-text {
  color: #020202;
}
#global-footer .footer-social-icons {
  display: block;
}
@media screen and (max-width: 767px) {
  #global-footer .footer-social-icons {
    text-align: center;
  }
}
#global-footer .footer-social-icons a {
  display: inline-block;
  padding: 2px;
  margin-right: 4px;
}
#global-footer .footer-social-icons a img {
  height: 29px;
  width: 29px;
}
#global-footer .social-icon-text {
  margin: 0;
}
#global-footer .social-icon-text p {
  display: none;
}
#footer-links {
  font-weight: 100;
}
#footer-links .security-logos {
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  #footer-links .security-logos {
    justify-content: left;
  }
}
.home-carousel-slider .carousel-indicators button.active {
  background-color: #020202;
}
@media (min-width: 576px) {
  .container {
    max-width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  #footer .footer-col {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 1560px;
    margin: 0 auto;
    padding: 0 30px;
  }
}
@media (min-width: 992px) {
  .p-12 {
    padding: 0 12px;
  }
}
@media screen and (max-width: 991px) {
  .eyebrowSection .container {
    padding: 0;
    overflow: hidden;
  }
  .mobileMenuLogo .vertical-center .text-center {
    display: none;
  }
  .close-navigation-menu-bar {
    border: 0;
    padding: 10px;
    background: transparent;
    display: none;
    position: relative;
    top: -10px;
  }
  .navMenu.mobileMenuActive .navigation-menu-bar {
    display: none;
  }
  .mobileMenuActive .close-navigation-menu-bar {
    display: block;
  }
  .mobileMenuLogo .headerHamburger {
    position: absolute;
    right: -17px;
    top: 13px;
  }
}
@media only screen and (min-width: 1200px) {
  #zone5 .button-text {
    top: 55%;
    position: absolute;
    z-index: 1;
  }
}
.featCats-container img,
.pageContent-banner img,
.z7-container img {
  width: 100%;
  height: auto;
}
.fw-bold,
.homeFeatureHeading {
  font-weight: 700;
}
.homeFeatureHeading {
  letter-spacing: -0.015em;
  font-size: 34px;
  color: #666;
  margin: 20px 40px 40px;
  text-align: center;
}
.main-container li {
  list-style: none;
}
.icn-x-close-hvy-circle:before {
  content: '';
}
.icn-check-sld-circle:before {
  content: '';
}
@media only screen and (max-width: 767px) {
  .apple-device .apple {
    display: block;
  }
  .apple-device .android {
    display: none;
  }
  .android-device .android {
    display: block;
  }
  .android-device .apple {
    display: none;
  }
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.rewards-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -180px -40px/200px
    200px;
}
.my-order-icon,
.rewards-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
}
.my-order-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) 0 -20px/200px 200px;
}
.registries-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -20px -20px/200px
    200px;
}
.lists-icon,
.registries-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
}
.lists-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -40px -80px/200px
    200px;
}
.stores-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -40px -20px/200px
    200px;
}
.free-resources-icon,
.stores-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
}
.free-resources-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -60px -20px/200px
    200px;
}
.services-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -80px -20px/200px
    200px;
}
.gift-cards-icon,
.services-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  float: left;
}
.gift-cards-icon {
  background: url(/assets/images/LakeshoreSprites_v16.svg) -100px -20px/200px
    200px;
}
@media (min-width: 767px) {
  body.stay-connected-open {
    padding-bottom: 64px;
  }
}
@media (min-width: 767px) and (max-width: 1200px) {
  body.stay-connected-open {
    padding-bottom: 94px;
  }
}
::-ms-reveal {
  display: none;
}
[data-namespace='s7classic'].s7flyoutzoomview {
  border: none;
}
#carouselIndicators .carousel-indicators,
#carouselIndicators_modal .carousel-indicators {
  position: absolute;
  z-index: 5;
  text-align: center;
  list-style: none;
}
#carouselIndicators .carousel-indicators button,
#carouselIndicators_modal .carousel-indicators button {
  display: inline-block;
  width: 12px;
  height: 12px;
  text-indent: -999px;
  margin: 1px 5px 1px 1px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 10px;
  border: none;
}
#carouselIndicators .carousel-indicators button.active,
#carouselIndicators_modal .carousel-indicators button.active {
  background-color: #020202;
}
#carouselIndicators .carousel-indicators {
  bottom: -30px;
  margin-bottom: 10px;
}
#carouselModal {
  overflow: hidden;
}
#carouselIndicators_modal {
  height: 100vh;
  display: flex;
  align-items: center;
}
#carouselIndicators_modal .carousel-indicators {
  bottom: 20px;
}
#carouselIndicators_modal [data-namespace='s7classic'].s7flyoutzoomview {
  border: none;
}
.filter-open {
  overflow: hidden;
}
.breadcrumb {
  margin: 0;
}
.modal-dialog-centered {
  align-items: flex-start;
}
@media (min-width: 768px) {
  .modal-dialog-centered {
    align-items: center;
  }
}
@media (min-width: 992px) {
  .tablet .nav-main_wrap > .navigation-list > a {
    pointer-events: none;
  }
}
.col-xs-auto {
  width: auto;
}
.col-xs-auto.flex-0 {
  flex: none;
}
.full-width-images img {
  width: 100%;
  height: auto;
}
.full-width-images .gap-3,
.nav-left .container {
  padding: 0 15px;
}
.nav-left .container img {
  max-width: 570px;
  height: auto;
}
.nav-left .justify-content-center .col-xl-2.col-md-3 {
  padding: 0 5px;
}
.nav-left .justify-content-center .col-xl-2.col-md-3:last-child {
  padding: 0 10px;
}
@media (min-width: 1200px) {
  .nav-left .justify-content-center .col-md-3 {
    padding: 0 13px;
  }
  .nav-left .justify-content-center .col-xl-6 {
    padding: 0 14px;
  }
  .nav-left .col-sm-6:last-child {
    padding: 0;
  }
}
@media (min-width: 1500px) {
  .nav-left .justify-content-center .col-xl-2.col-md-3 {
    padding: 0 10px;
  }
  .nav-left .container {
    padding: 0 29px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .nav-left .container {
    padding: 0 15px;
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(-45%);
  }
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
}
@keyframes slideOutLeft {
  0% {
    opacity: 1;
    transform: translateX(-50%);
  }
  to {
    opacity: 0;
    transform: translateX(-55%);
  }
}
@keyframes arrowOpacity {
  0% {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
}
@keyframes arrowOpacityTwo {
  0% {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
.eyebrow-slider .carousel-item {
  display: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin: 0;
  width: auto;
}
.eyebrow-slider .carousel-item.active {
  display: inline-block;
  opacity: 1;
  position: relative;
  animation: slideInRight 1.5s forwards;
}
.eyebrow-slider .carousel-item-next.carousel-item-start {
  animation: slideOutLeft 1s forwards;
}
.eyebrow-slider:has(div.carousel-item) button {
  animation: arrowOpacityTwo 1s forwards;
}
.eyebrow-slider:has(div.carousel-item-next) button {
  animation: arrowOpacity 2s forwards;
  animation-delay: 0.3s;
}
.eyebrow-modal-wrapper .container {
  padding: 0 5px;
}
.navigation-list.active > div {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.2s;
}
.isGSAUser .gsauser-heroImage {
  margin: 20px auto;
}
.footer-badge-wrap {
  display: none;
}
@media (min-width: 768px) {
  .footer-badge-wrap {
    display: block;
  }
}
body.route-is-loading main {
  visibility: hidden;
  opacity: 0;
}
object[id^='DigiCertClickID_'] {
  width: 77px !important;
}
.change-store-msg strong {
  font-weight: 700;
}
.ll-select-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.ll-select-wrap {
  position: relative;
}
.ll-select {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #020202;
  font-size: 14px;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  padding: 8px 35px 8px 6px;
  font-weight: 100;
  -webkit-appearance: none;
  height: 44px;
}
@media (min-width: 768px) {
  .ll-select {
    height: 38px;
  }
}
.ll-select:focus-visible {
  border-color: rgba(102, 175, 233, 0.6);
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  outline: none;
}
.ll-select option {
  font-weight: 100;
}
.caret-select {
  position: absolute;
  right: 12px;
  top: 45%;
  z-index: 1;
  pointer-events: none;
  border-top: 7px solid;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
@media (min-width: 992px) {
  .caret-select {
    top: 50%;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
.modal-dialog {
  max-width: 100%;
  margin: 20px;
}
.ll-modal-sm {
  max-width: 375px;
}
.ll-modal-md {
  max-width: 600px;
}
.ll-modal-lg {
  max-width: 650px;
}
.ll-modal-lgr {
  max-width: 700px;
}
.ll-modal-xl {
  max-width: 798px;
}
.ll-modal-xxl {
  max-width: 1140px;
}
.ll-modal-top {
  margin-top: 20px;
}
@media (min-width: 576px) {
  .ll-modal-top {
    margin-top: 70px;
  }
}
.modal-link {
  cursor: pointer;
  color: #007daa;
}
.modal-link:hover {
  text-decoration: underline;
}
.ll-modal-body-content-center {
  text-align: center;
}
.table-modal {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  box-sizing: border-box;
}
.table-modal ul {
  margin-left: 20px;
  margin-bottom: 10px;
}
.table-modal ul li {
  list-style: disc;
}
.chart-row,
.table-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  flex-direction: row;
}
.bold,
strong {
  font-weight: 700;
}
.medium {
  font-weight: 300;
}
.light {
  font-weight: 100;
}

.ll-fs-12 {
  font-size: 12px;
}
.ll-fs-13 {
  font-size: 13px;
}
.ll-fs-14 {
  font-size: 14px;
}
.ll-link-cursor {
  cursor: pointer;
}
.tablet .singleStackTablet {
  flex: none;
  padding: 0;
  margin-bottom: 30px;
}
.mobile .singleStackMobile {
  margin-bottom: 30px;
}
.ll-mr-7 {
  margin-right: 7px;
}
.co-blue {
  color: #174878;
}
.underline {
  text-decoration: underline;
  color: #000;
}
html[data-smartbanner-original-margin-top='0'] .find-store-modal {
  margin-top: 205px;
}
.error-msg {
  color: #e60e0e;
}
.btn-height-wrap button {
  min-height: 38px;
}
#dynamicModal > div {
  display: none;
}
#dynamicModal > div:last-child {
  display: block;
}
#dynamicModalWrapper #dynamicModal .added-registry-modal {
  display: flex;
}
@media (min-width: 1200px) and (max-width: 1450px) {
  #dynamicModalWrapper #dynamicModal .added-registry-modal {
    margin-top: 50px;
  }
}
[data-namespace='s7classic'].s7flyoutzoomview .s7tip {
  display: none !important;
}
@media screen and (max-width: 767px) {
  #largeTextarea {
    min-height: auto;
    height: 34px;
  }
}
.icn-plus-lgt:before {
  content: '';
}
.icn-minus-lgt:before {
  content: '';
}
.smallbody {
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
}
.light .smallbody {
  font-weight: 100;
}
.googlePay {
  flex: 1 1;
}
#googlePayBtn .ll-btn-container button {
  min-width: auto;
  height: 45px;
}
@media (min-width: 768px) {
  #googlePayBtn .ll-btn-container button {
    height: inherit;
  }
}
@media (max-width: 768px) {
  .enhance-mobile-search {
    overflow: hidden;
  }
  .enhance-mobile-search .account-icons,
  .enhance-mobile-search .header-wrapper .hideHeaderElem,
  .enhance-mobile-search .hideHeaderElem {
    display: none;
  }
  .enhance-mobile-search .header-wrapper {
    height: 50px;
  }
  .enhance-mobile-search .header-wrapper .header-right {
    width: 100%;
    padding: 0;
  }
}
.no-transition {
  transition: none !important;
}
.opaque {
  opacity: 0.4;
  display: none;
}
@media (min-width: 768px) {
  .show-opaque .headerWrapper {
    position: relative;
    z-index: 1051;
    background-color: #fff;
  }
  .show-opaque .opaque {
    display: block;
  }
}
.ll-text-black {
  color: #000;
}

.ls-icon {
  font-family: 'ls-icon';
}
// DONT ADD ANY NEW STYLES IN THIS GLOBAL CSS
